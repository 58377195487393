import './App.css';
import HomePage from './HomePage';

function App() {
  return (
    <HomePage>
      
    </HomePage>
  );
}

export default App;
