import logo from './assets/logo192.png'
import './App.css'
import { useState, useEffect, useRef} from 'react'

const HomePage = () => {

  // const fileUrl = apkPath
  const fileUrl = process.env.PUBLIC_URL + "/files/cardrecorder1123.apk"
  const beianUrl = "https://beian.miit.gov.cn"
  const publicDir = process.env.PUBLIC_URL
  console.info("public: " + fileUrl)

  return (
    <div className="HomePage" style={{
      width: "100%", 
      height: "auto",
      display: "flex", 
      flexDirection: "column",
      alignItems: "center",
      lineHeight: 2,
      maxWidth: "750px",
      minWidth: "325px",
      borderLeft: "1px solid lightgray",
      borderRight: "1px solid lightgray",
      padding: "0px",
      borderRadius: "4px",
      color: '#464646',
      fontSize: "18px",
      // backgroundColor: "#554DE4",
    }}>

      <div className='title' style={{
        width: "auto", 
        height: "auto", 
        display: "flex", 
        flexDirection: "column",
        justifyContent: "left",
        alignItems: "start",
        fontSize: "16px",
        marginLeft: "0px",
      }}>
        <img alt='例图1' src={publicDir+"/files/site1.jpg"} style={{ width: "100%" }}></img>
      </div>

      <div style={{
        width: "100%", 
        height: "20px",
      }}></div>

      <a style={{color: "blue", fontSize: "24px"}} href={fileUrl} download={"cardrecorder1123.apk"}>
          安卓手机下载
      </a>

      <div style={{width:"10px", height: "500px"}}></div>

      <a style={{color: "#464646", fontSize: "12px", textDecoration: "none"}} href={beianUrl}>
      蜀ICP备2023030177号-2
      </a>

    </div>
  );
}

export default HomePage;
